.tabTitle-select{
  color: var(--black, #333333);
  /* font-family: "SF Compact"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 656;
  line-height: normal;
}


.tabTitle-unselect{
  color: var(--black, #333333);
  /* font-family: "SF Compact"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  opacity: 0.4;
}

.Avatar{
  /* width: 640px;
  height: 360px; */
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

@media (max-width: 576px) {
  .Avatar{
    height: 280px; 
  }
}