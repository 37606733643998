/* 栅格容器 */
.grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* 在中等屏幕上变为8列 */
    max-width: 552px;
    margin: 0 auto; /* 让栅格在页面居中 */
    padding: 40px 40px 0px 40px;
    gap: 48px;
    grid-auto-rows: auto; /* 确保行高自适应内容 */
    animation: fade 0.5s ease-in-out; 
    position: relative; /* 使内部的绝对定位元素参考该容器 */
}

@media (max-width: 576px) {
  .grid-container {
      padding-top: 20px;
  }
}

@media (max-width: 1240px) {
    .grid-container {
        gap: 20px;
    }
}
  
  @media (min-width: 1223px) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* 在中等屏幕上变为8列 */
        max-width: 1154px;
        width: 100%;
        grid-auto-rows: auto; /* 确保行高自适应内容 */
    }
  }
  
  /* 栅格项 */
  .grid-item-1 {
    grid-column: span 1;
  }

  .grid-item-2 {
    grid-column: span 2;
  }

  .grid-item-3 {
    grid-column: span 3;
  }

  .grid-item-4 {
    grid-column: span 4;
  }

  .column{
    display: flex; 
    flex-direction: column; /* 垂直排列 */
    gap: 20px;
    flex: 1; /* 列占据相同宽度 */
  }

  .row{
    display: flex; 
    flex-direction: row; /* 水平排列 */
    gap: 20px;
    flex: 1; /* 列占据相同宽度 */
  }

  .fixed-column {
    grid-column: 4 / 4; /* 放置在第4列 */
    position: fixed;
    padding-top: 40px;
  }

@media (max-width: 768px) {
    .hidden-on-mobile {
        display: none;
    }
}

/* Safari的bug，在动画结束后anchor导航回跳回到col1*/
@supports (image-rendering: optimizeSpeed) {
    .fixed-column {
        padding-top: 0px!important;
        margin-left: 900px !important;
    }
  }
  
.ant-tabs-top>.ant-tabs-nav:before {
    border-bottom: transparent;
}

.ant-tabs-tab-active {
    font-weight: bold; /* 选中项字体加粗 */
}

/* 淡入淡出动画 */
@keyframes fade {
    0% {
      /* transform: translate(0,-20px); */
      top:-20px;
      opacity: 0;
    }
    100% {
      /* transform: translate(0,0); */
      top:0px;
      opacity: 1;
    }
}
.blogWebTitle{
  color: var(--black, #333333);
  /* font-family: "SF Compact"; */
  font-size: 40px;
  font-style: normal;
  font-weight: 656;
  line-height: normal;
  padding-top: 8px;
}

.blogLargeTitle{
    color: var(--black, #333333);
    /* font-family: "SF Compact"; */
    font-size: 18px;
    font-style: normal;
    font-weight: 656;
    line-height: normal;
}

.blogTitle{
    color: var(--black, #333333);
    /* font-family: "SF Compact"; */
    font-size: 16px;
    font-style: normal;
    font-weight: 656;
    line-height: normal;
}

.blogText{
    color: #333333;
    text-align: justify;
    /* font-family: "PingFang SC"; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.9;
    line-height: 20px;
}

.blogLargeCaption{
  color: #333333;
  text-align: justify;
  /* font-family: "PingFang SC"; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
  line-height: 20px;
  padding-top: 4px;
}

.blogCaption{
    color: #333333;
    text-align: justify;
    /* font-family: "PingFang SC"; */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
    line-height: 20px;
    padding-top: 4px;
}

ul{
  padding-top: 8px;
}

li {
    opacity: 1;
    margin-bottom: 20px; /* 设置列表项间距 */
    list-style-type: disc; /* 保持圆点 */
}

li:hover {
    opacity: 0.7; /* 悬停时透明度变化 */
    transition: opacity 0.2s ease; /* 设置颜色的过渡动画 */
}

a {
    color: inherit; /* 使用继承的颜色，或设置为其他颜色 */
    text-decoration: none; /* 移除下划线 */
}

a:hover {
    color: #333333; /* 悬停时改变颜色 */
    /* opacity: 0.7; */
}

.tipBox{
  display: flex;
  background-color: rgba(0, 0, 0, 0.03);
  width: 98%;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.10);
}
  

.Copyright{
  color: var(--gray, #999);
  /* font-family: "SF Pro"; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 40px;
  padding-bottom: 60px;
}

.Gallery-container {
  display: flex;
  flex-wrap: wrap;        /* 允许内容换行 */
  gap: 16px;              /* 设置图片间的间距 */
  align-items: stretch;
}

.GalleryImage{
  flex: 1 1 30%;         /* 每个图片占据容器的约 30% */
  object-fit:cover;
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 1223px) {
  .GalleryImage{
    flex: 1 1 40%;         /* 每个图片占据容器的约 30% */
    /* flex: 0 1 34%;  不扩展，允许收缩，初始宽度为40% */
  }
}

@media (max-width: 576px) {
  .GalleryImage{
    flex: 1 1 100%;         /* 每个图片占据容器的约 30% */
    /* flex: 0 1 34%;  不扩展，允许收缩，初始宽度为40% */
  }
}

.ant-image{
  width: 100%!important;
  height: 100% !important;
}

.ant-image-img{
  height: 100% !important;
  object-fit:cover !important;
}

.video{
  border-radius: 12px;
  overflow: hidden;    /* 确保内容不超出圆角边界 */
  object-fit:cover;
}

.video:playing{
  object-fit:contain;
}