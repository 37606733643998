.CardContainer{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content:center;
    -webkit-user-drag: none; /* 针对 Safari 和 Chrome */
    -moz-user-select: none;  /* 针对 Firefox */
    -ms-user-select: none;   /* 针对 IE 和 Edge */
    user-select: none;       /* 针对现代浏览器 */
    pointer-events: none;    /* 禁用鼠标事件 */
}

.CardImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease; /* 添加平滑过渡效果 */
    /* max-width: 552px; */
    -webkit-user-drag: none; /* 针对 Safari 和 Chrome */
    -moz-user-select: none;  /* 针对 Firefox */
    -ms-user-select: none;   /* 针对 IE 和 Edge */
    user-select: none;       /* 针对现代浏览器 */

}

.CardImg:hover {
    transform: scale(1.05); /* 在悬浮时放大1.05倍 */
}

.CardImgContainer{
    position: relative; /* 使内部的绝对定位生效 */
    width: 100%;
        /* height: 414px; */
    overflow: hidden;
    border-radius: 12px;
}

.CardTitle{
    color: var(--black, #333);
    /* font-family: "SF Pro"; */
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 16px;
    -webkit-user-drag: none; /* 针对 Safari 和 Chrome */
    -moz-user-select: none;  /* 针对 Firefox */
    -ms-user-select: none;   /* 针对 IE 和 Edge */
    user-select: none;       /* 针对现代浏览器 */
}

.CardTime{
    color: var(--gray, #999);
    /* font-family: "SF Pro"; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding-top: 4px;
    line-height: normal;
    -webkit-user-drag: none; /* 针对 Safari 和 Chrome */
    -moz-user-select: none;  /* 针对 Firefox */
    -ms-user-select: none;   /* 针对 IE 和 Edge */
    user-select: none;       /* 针对现代浏览器 */
}

.CardIntro{
    color: var(--gray, #666666);
    /* font-family: "SF Pro"; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding-top: 20px;
    line-height: normal;
    -webkit-user-drag: none; /* 针对 Safari 和 Chrome */
    -moz-user-select: none;  /* 针对 Firefox */
    -ms-user-select: none;   /* 针对 IE 和 Edge */
    user-select: none;       /* 针对现代浏览器 */
    pointer-events: none;    /* 禁用鼠标事件 */
}

.CardAward{
    width: 96px;
    height: 96px;
    overflow: hidden;
    position: absolute;
    z-index: 1; 
    right: 48px;
    top: 48px;
    -webkit-user-drag: none; /* 针对 Safari 和 Chrome */
    -moz-user-select: none;  /* 针对 Firefox */
    -ms-user-select: none;   /* 针对 IE 和 Edge */
    user-select: none;       /* 针对现代浏览器 */
    pointer-events: none;    /* 禁用鼠标事件 */
}

@media (max-width: 576px) {
    .CardAward {
        width: 64px;
        height: 64px;
        right: 32px;
        top: 32px;
    }
}