.AwardImg{
    height: 88px;
}

.Wheat{
    height: 88px;
}

.WheatContainer{
    display: flex;
    align-items: center;
}

.AwardContainer{
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}

.AwardtextContainer{
    display: flex;
    gap: 2px;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
}

@media (max-width: 576px) {
    .AwardContainer{
        gap: 20px;
    }
    .AwardImg{
        height: 64px;
    }
    .Wheat{
        height: 64px;
    }
}
/* @media (max-width: 576px) {
    .AwardContainer{
      height: 280px; 
    }
} */

.toolbar-wrapper {
    padding: 12px 24px;
    color: #fff;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    -moz-backdrop-filter: blur(20px);
    -o-backdrop-filter: blur(20px);
    -ms-backdrop-filter: blur(20px);
}

.more{
    cursor: pointer;
    color: #333333;
    text-align: justify;
    /* font-family: "PingFang SC"; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.9;
    line-height: 20px;
}

.more:hover{
    opacity: 0.7;
}