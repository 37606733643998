.WebLogo {
    width: 225px;
    height: 18px;
    -webkit-user-drag: none; /* 针对 Safari 和 Chrome */
    -moz-user-select: none;  /* 针对 Firefox */
    -ms-user-select: none;   /* 针对 IE 和 Edge */
    user-select: none;       /* 针对现代浏览器 */
    pointer-events: none;    /* 禁用鼠标事件 */
}
  
.Navigator{
    position: fixed; /* 将位置设置为固定 */
    top: 0; /* 固定在视口顶部 */
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(252, 252, 252, 0.80);
    
    width: 100%;
    height: 88px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10); /* 下边框宽度、样式和颜色 */
    z-index: 1000; /* 确保导航栏在其他内容之上 */
  
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    -moz-backdrop-filter: blur(20px);
    -o-backdrop-filter: blur(20px);
    -ms-backdrop-filter: blur(20px);
}

@media (max-width: 576px) {
  .Navigator{
      height: 56px;
  }
  .WebLogo {
    width: 150px;
    height: 12px;
  }
}

.backButton{
  cursor: pointer;
  width: 32px;
  position: absolute;
  left: 40px;
}